
import '../scss/app.scss';

import 'jquery';
import 'popper.js';

//save the webpack version of jquery as the globally-accessed jquery object
//which will include the associated bootstrap functionality once the below imports finish
global.jQuery=jQuery;
global.$=global.jQuery;

//import 'bootstrap';

//pick just the bootstrap modules which we are actually using
//so as to not load things we don't need
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/util';

// Drodown Menu Hover Open
const $dropdown = $(".dropdown");
const $dropdownToggle = $(".dropdown-toggle");
const $dropdownMenu = $(".dropdown-menu");
const showClass = "show";
//find the given space-delimited word within a given string
//note that if the word is part of a larger word it won't be considered found; e.g. findWord('and a b','a') results in 4 NOT 0
//returns -1 if word is not in the string; otherwise returns the index of the first occurance of the word within the string
function findWord(string,word){
	var word_idx=string.indexOf(word);

	//if this word is a substring that's included as part of a longer word, then skip that instance
	//we can check this by checking if one of the following conditions are met:
	//  word_idx is greater than or equal to 0 and the string continues after the word and the character immediately after the word is not whitespace
	//    (non-whitespace is determined by checking if character.trim()!='')
	//  the name_idx is greater than 0 and the character immediately before the word is not whitespace
	while(((word_idx>=0) && (string.length>(word_idx+word.length)) && (string.charAt(word_idx+word.length).trim()!='')) || ((word_idx>0) && (string.charAt(word_idx-1).trim()!=''))){
		word_idx=string.indexOf(word,word_idx+1);
	}
	return word_idx;
}

//remove a space-delimited word from a given string, and return the updated string with all occurrences of that word omitted
//note that if the word is part of a larger word it won't be removed; e.g. removeWord('a and b','a') results in 'and b' NOT 'nd b'
function removeWord(string,word){
	do {
		var word_idx=findWord(string,word);
		
		if(word_idx>=0){
			//the new string is everything before the word up through everything after the word
			//the trim() just cleans up some whitespace at the ends in case a word was removed from the start or end of the string
			string=(string.substring(0,word_idx)+string.substring(word_idx+word.length,string.length)).trim();
		}
	} while(word_idx>=0);

	//now that we've removed every occurrance of word within the string, return the new string
	return string;
}

//remove a class on an element in a more efficient way than jquery
function removeClass(element,class_name){
	//the is the same operation as removing a space-delimited word from a substring
	//so call into that
	element.className=removeWord(element.className,class_name);
}

//add a class to an element in a more efficient way than jquery
function addClass(element,class_name){
	//note that we remove any occurrances of the class name first so that we don't have duplication of class names
	element.className=removeWord(element.className,class_name)+' '+class_name;
}

//toggle a class on an element in a more efficient way than jquery
function toggleClass(element,class_name){
	if(findWord(element.className,class_name)<0){
		addClass(element,class_name);
	}else{
		removeClass(element,class_name);
	}
}

$(window).on("load resize", function() {
	if (this.matchMedia("(min-width: 1024px)").matches) {
		$dropdown.hover(
			function() {
				const $this = $(this);
				$this.addClass(showClass);
				$this.find($dropdownToggle).attr("aria-expanded", "true");
				$this.children($dropdownMenu).addClass(showClass);
			},
			function() {
				const $this = $(this);
				$this.removeClass(showClass);
				$this.find($dropdownToggle).attr("aria-expanded", "false");
				$this.find($dropdownMenu).removeClass(showClass);
			}
		);
	} else {
		$dropdown.off("mouseenter mouseleave");
	}
});

$(window).scroll(function() {
	var headerHeight = $('#navbar').outerHeight();
	var preNavheight = $('.pre-nav').outerHeight();
	var siteHeader = document.getElementById('navbar');
	var body = document.getElementsByTagName('body')[0];

	var height = headerHeight;
	if (window.innerWidth >= 1024) {
		height = preNavheight;
	}

	var scrollTop = $(this).scrollTop();
	if(scrollTop > height){
		if (findWord(siteHeader.className,'sticky') < 0) {
			// DO SOMETHING AFTER SCROLL DOWN
			addClass(siteHeader, 'sticky');
			addClass(body, 'menu-is-sticky');
			$('body').css('padding-top', headerHeight);

		}
	} else if (findWord(siteHeader.className,'sticky') >= 0) {
		removeClass(siteHeader,'sticky');
		removeClass(body,'menu-is-sticky');
		$('body').css('padding-top', 0);
	}
});

$(document).ready(function() {
	if($('.post-share')) {
    var current_page = location.href;
    var page_title = document.getElementsByClassName('post-title');
    var post_excerpt = document.getElementsByClassName('post-excerpt');
    var image_src = '';

    if($('.single-hero').length > 0) {
      image_src = $('.single-hero').attr('src');
    } else {
      $('.share-button.pinterest').hide();
    }

    if(post_excerpt[0]) {
      post_excerpt = post_excerpt[0].innerText;
    } else {
      post_excerpt = '';
    }

    if(page_title[0]) {
      page_title = page_title[0].innerText;
    } else {
      page_title = '';
    }

    $('.facebook').attr("href", "https://www.facebook.com/sharer/sharer.php?u="+current_page+"&text="+page_title);
    $('.twitter').attr("href", "https://www.twitter.com/share?url="+current_page+"&text="+page_title);
    $('.linkedin').attr("href", "https://www.linkedin.com/shareArticle?mini=true&url="+current_page+"&title="+page_title);
    $('.email-post').attr("href", "mailto:?Subject=Shared%20from%20Runkeeper&Body=I%20saw%20this%20and%20thought%20of%20you!%20 "+current_page+"&text="+page_title);
    $('.share-icon.pinterest').attr("href", "http://pinterest.com/pin/create/button/?url="+current_page+"&media="+image_src+"&description="+encodeURI(post_excerpt));
  }

  $('.copy-link').click(function() {
    var current_url_input = document.createElement('input'), url_text = window.location.href;
    document.body.appendChild(current_url_input);
    current_url_input.value = url_text;
    current_url_input.select();
    document.execCommand('copy');
    document.body.removeChild(current_url_input);
    alert('The page URL has been copied to your clipboard');
  });
});

//Search Bar focus and hover events
$('document').ready(function() {
	//toggle search via click on mobile
	function toggleSearchMobile(event,searchForm,searchSubmit,searchField) {
		let searchformCont = document.getElementsByClassName('searchform-cont')[0];
		let navbar = document.getElementById('navbar');
		//if the search form is currently active
		if(searchForm.classList.contains('form-active')){
			//but there's nothing to be searched for
			if(searchField.value===''){
				//then close the search dialogue
				event.preventDefault();
				searchForm.classList.remove('form-active');
				searchformCont.classList.remove('cont-form-active');
				navbar.classList.remove('navbar-form-active');
				return false;
			}
			//if there IS something to search for then the default action will perform the search
			//so we just need to not run preventDefault here
		//if the search form is NOT currently active then activate it now
		}else{
			event.preventDefault();
			searchForm.classList.add('form-active');
			searchformCont.classList.add('cont-form-active');
			navbar.classList.add('navbar-form-active');
			return false;
		}
	}

	//toggle search via hover on desktop
	function toggleSearchDesktop(event,searchForm,searchSubmit,searchField) {
		let searchformCont = document.getElementsByClassName('searchform-cont')[0];
		if((searchField===document.activeElement) || document.querySelector('#searchform-desktop:hover') || document.querySelector('#searchsubmit-desktop:hover')){
			searchForm.classList.add('form-active');
			searchformCont.classList.add('cont-form-active');
		}else{
			searchForm.classList.remove('form-active');
			searchformCont.classList.remove('cont-form-active');
		}
	}

	//detect the screen resolution and toggle the menu in an appropriate way for the screen size
	function toggleSearch(event){
		if(window.innerWidth>=1024){
			let searchForm=document.getElementById('searchform-desktop');
			let searchField=searchForm.firstElementChild.firstElementChild;
			let searchSubmit=document.getElementById('searchsubmit-desktop');

			return toggleSearchDesktop(event,searchForm,searchSubmit,searchField);
		}else{
			let searchForm=document.getElementById('searchform-mobile');
			let searchField=searchForm.firstElementChild.firstElementChild;
			let searchSubmit=document.getElementById('searchsubmit-mobile');

			//on mobile only the click event triggers the toggle
			if(event.type==='click' || event.type==='blur'){
				return toggleSearchMobile(event,searchForm,searchSubmit,searchField);
			}
			//other events do nothing
			event.preventDefault();
			return false;
		}
	};

	function moveNavBar() {
		let navbar = document.getElementById('navbar');
		navbar.classList.toggle('mobile-nav-open');
	}

	let searchFormDesktop = document.getElementById('searchform-desktop');
	let searchFieldDesktop = searchFormDesktop.firstElementChild.firstElementChild;
	let searchSubmitDesktop = document.getElementById('searchsubmit-desktop');

	searchFormDesktop.addEventListener('hover', toggleSearch);
	searchFormDesktop.addEventListener('mouseover', toggleSearch);
	searchFormDesktop.addEventListener('mouseout', toggleSearch);
	searchFieldDesktop.addEventListener('focus', toggleSearch);
	searchFieldDesktop.addEventListener('blur', toggleSearch);
	searchSubmitDesktop.addEventListener('hover', toggleSearch);
	searchSubmitDesktop.addEventListener('mouseover', toggleSearch);
	searchSubmitDesktop.addEventListener('mouseout', toggleSearch);

	let searchFormMobile = document.getElementById('searchform-mobile');
	let searchFieldMobile = searchFormMobile.firstElementChild.firstElementChild;
	let searchSubmitMobile = document.getElementById('searchsubmit-mobile');

	searchSubmitMobile.addEventListener('click', toggleSearch);
	searchFieldMobile.addEventListener('blur', toggleSearch);

	let mainNavToggle = document.getElementById("main-nav-toggle");

	mainNavToggle.addEventListener('click', moveNavBar);

	$('[data-toggle="tooltip"]').tooltip();
});

$(document).ready(function() {
	var mobileNav = document.getElementById('mobile-nav');
	var mobileSearchformCont = document.getElementById('mobile-searchform-cont');

	if (mobileNav && mobileSearchformCont) {
		mobileNav.prepend(mobileSearchformCont);
	}
	if (mobileSearchformCont) {
		mobileSearchformCont.classList.remove('hidden');
	}
});

$(".menu-item-has-children.dropdown").on("click", ".dropdown-menu", function (e) {
	console.log($(this).parent());
	$(this).parent().is(".show") && e.stopPropagation();
	
	$("[aria-labelledby="+e.target.id+"]").toggleClass('show');
});
